import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OhcService } from './ohc.service';
import { ComponentType, HxStoreService } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class CheckStoreGuard implements CanActivate {

  constructor(
    private router: Router,
    private ohcService: OhcService,
    private storeService: HxStoreService
  ) {}

  async canActivate() {
    const store = this.ohcService.getStoreId();
    const stores = await this.storeService.getAllStoresByCurrentUserAndComponentType(ComponentType.cf);

    if (!store) {
      if (stores.length > 1) {
        this.router.navigateByUrl('/verify');
        return false;
      } else {
        if (stores.length == 0) {
          this.router.navigateByUrl('/verify');
          return false;
        } else {
          this.ohcService.setStoreId(stores[0]?.id);
        }
      }
    }

    return true;
  }
}
