<div class="modal-body" *ngIf="invoice">
  <ng-container *ngIf="invoice.products?.length && !isLoading.invoice">
    <button *ngIf="!isPartially && canSend" type="button" style="position: absolute;right: 30px;"
            class="hc-button hc-button--primary" (click)="setPartiallyInvoice()">{{ 'buttons.partialShipment' | transloco }}
    </button>

    <h4 class="hc-modal__title">{{ 'invoice-modal.invoice' | transloco }} №{{ invoice.id }} {{ 'invoice-modal.from' | transloco }} {{ invoice.sendDate | date: 'dd.MM.yyyy HH:mm' }}</h4>
    <p>
      {{ 'invoice-modal.send' | transloco }} {{ invoice.senderUser?.fullname }}
    </p>
    <div>
      <table class="hc-table hc-table--striped">
        <thead class="hc-table__head">
        <tr class="hc-table__row hc-table__row--head">
          <th class="hc-table__cell">
            <span>{{ 'invoice-modal.table.head.product' | transloco }}</span>
          </th>
          <th class="hc-table__cell is--align-right">
            <span>{{ 'invoice-modal.table.head.quantity' | transloco }}</span>
          </th>
          <th *ngIf="isPartially" class="hc-table__cell is--align-center">
            <span>{{ 'invoice-modal.table.head.send' | transloco }}</span>
          </th>
          <th *ngIf="isPartially" class="hc-table__cell is--align-center">
            <span>{{ 'invoice-modal.table.head.remain' | transloco }}</span>
          </th>
        </tr>
        </thead>
        <tbody class="hc-table__body">
        <ng-container *ngFor=" let even = even;let i = index; let productItem of invoice.products">
          <tr *ngIf="productItem.productInfoId" class="hc-table__row" [ngClass]="{ 'hc-table__row--even': even }">
            <td class="hc-table__cell">
              {{ productItem.productInfoTitle | uiLabel }}
            </td>
            <td class="hc-table__cell is--align-right" style="width: 60px;">
              {{ productItem.amount }}
            </td>
            <td *ngIf="isPartially" class="hc-table__cell is--align-right" style="width: 60px;">
              <input type="number" [(ngModel)]="productItem.newValue" class="hc-input is--align-center"
                     style="height: auto;margin: 0;">
            </td>
            <td *ngIf="isPartially" class="hc-table__cell is--align-right" style="width: 60px;">
              {{ productItem.amount - productItem.newValue }}
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>

  <h4 *ngIf="!invoice.products?.length && isLoading.invoice">
    {{ 'invoice-modal.loader.success' | transloco }}
  </h4>

  <h4 *ngIf="!invoice.products?.length && !isLoading.invoice">
    {{ 'invoice-modal.loader.fatal' | transloco }}
  </h4>

  <hc-invoice-events *ngIf="!canSend" [id]="invoice.id"></hc-invoice-events>

  <div class="hc-modal__footer is--align-right" *ngIf="canSend && invoice.products?.length && !isLoading.invoice">
    <button type="button" class="hc-button hc-button--link" (click)="modal.dismiss()">{{ 'buttons.cancel' | transloco }}</button>

    <button *ngIf="!isPartially" type="button" class="hc-button hc-button--success" (click)="sendInvoice()">
      {{ 'buttons.send' | transloco }}
    </button>

    <button type="button" *ngIf="isPartially" class="hc-button hc-button--success" (click)="sendPartiallyInvoice()">
      <span>
        {{ 'buttons.submitSelected' | transloco }}
      </span>
    </button>
  </div>
</div>
