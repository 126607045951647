import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxAuthService, HxInvoiceService, InvoiceModel, InvoiceProductAmountModel, InvoiceProductModel, InvoiceStatus } from 'hx-services';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

interface InvoiceItem extends InvoiceModel {
  products: InvoiceProductItem[];
}

interface InvoiceProductItem extends InvoiceProductModel {
  newValue?: number;
}

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice.modal.html'
})
export class InvoiceModalComponent implements OnInit {

  @Input() invoice: InvoiceItem;
  isLoading = {
    invoice: false,
  };
  storeId: number;
  isPartially = false;
  canSend = false;

  constructor(
    private toastr: HxToastrService,
    private invoiceService: HxInvoiceService,
    public modal: NgbActiveModal,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit(): void {
    this.storeId = this.auth.user.store.id;
    this.canSend = this.invoice.status === InvoiceStatus.NEW;
    this.isLoading.invoice = true;
    this.invoiceService.getInvoiceById(this.invoice.id).subscribe(result => {
      this.invoice = result;
      this.isLoading.invoice = false;
    }, () => this.isLoading.invoice = false);
  }

  sendInvoice() {
    if (!this.invoice && !this.invoice.id) {
      return this.showCommonToastrError();
    }

    this.invoiceService.sendInvoice(this.invoice.id).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.ts.sentSuccess'));
      this.modal.close();
    }, () => this.showCommonToastrError());
  }

  setPartiallyInvoice() {
    this.isPartially = true;
    this.invoice.products.forEach(item => item.newValue = 0);
  }

  sendPartiallyInvoice() {
    const products: InvoiceProductAmountModel[] = [];

    this.invoice.products.forEach(item => {
      if ((item.amount - item.newValue) >= 0 && item.newValue > 0) {
        products.push({amount: item.newValue, productInfoId: item.productInfoId});
      }
    });

    if (!products.length) {
      return this.toastr.warning(this.tr.translate('invoice-modal.ts.numberSent'));
    }

    this.invoiceService.sendPartially(this.invoice.id, products).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.ts.invoiceSent'));
      this.modal.close();
    }, () => this.showCommonToastrError());
  }

  private showCommonToastrError() {
    this.toastr.error(this.tr.translate('invoice-modal.ts.error'));
  }
}
