<header class="hc-subheader">
  <div class="header-button">
    <button class="hc-button hc-button--primary hc-button--l hc-steps__button-sticky" (click)="back()"></button>
    <span class="hc-subheader__time">
      <!-- {{ currentDatetime | date: 'HH:mm' }} -->
    </span>
  </div>
  <svg class="is--logo" width="55" height="19" viewBox="0 0 55 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.968018 0.816008H4.20802V7.68001H11.432V0.816008H14.672V18H11.432V10.752H4.20802V18H0.968018V0.816008Z" fill="#0788FF"/>
    <path d="M26.5181 18.384C24.8541 18.384 23.3341 17.992 21.9581 17.208C20.5821 16.408 19.4941 15.328 18.6941 13.968C17.9101 12.592 17.5181 11.072 17.5181 9.40801C17.5181 7.74401 17.9101 6.23201 18.6941 4.87201C19.4941 3.49601 20.5821 2.41601 21.9581 1.63201C23.3341 0.832007 24.8541 0.432007 26.5181 0.432007C29.1101 0.432007 31.2541 1.36801 32.9501 3.24001L30.6701 5.44801C29.5661 4.15201 28.1901 3.50401 26.5421 3.50401C25.4701 3.50401 24.4941 3.75201 23.6141 4.24801C22.7341 4.72801 22.0381 5.41601 21.5261 6.31201C21.0301 7.20801 20.7821 8.24001 20.7821 9.40801C20.7821 10.576 21.0301 11.608 21.5261 12.504C22.0381 13.4 22.7341 14.096 23.6141 14.592C24.4941 15.072 25.4701 15.312 26.5421 15.312C28.3501 15.312 29.8621 14.56 31.0781 13.056L33.3821 15.24C32.5341 16.264 31.5261 17.048 30.3581 17.592C29.2061 18.12 27.9261 18.384 26.5181 18.384Z" fill="#0788FF"/>
    <path d="M36.3821 0.816008H39.6221V18H36.3821V0.816008Z" fill="#0788FF"/>
    <path d="M48.7145 18.384C47.2105 18.384 45.8825 17.96 44.7305 17.112C43.5785 16.264 42.7705 15.064 42.3065 13.512L45.3545 12.312C45.5945 13.224 46.0025 13.952 46.5785 14.496C47.1545 15.04 47.8745 15.312 48.7385 15.312C49.4585 15.312 50.0745 15.136 50.5865 14.784C51.0985 14.416 51.3545 13.912 51.3545 13.272C51.3545 12.632 51.1065 12.112 50.6105 11.712C50.1305 11.296 49.2665 10.856 48.0185 10.392L46.9625 10.008C45.8585 9.62401 44.9145 9.04001 44.1305 8.25601C43.3465 7.45601 42.9545 6.45601 42.9545 5.25601C42.9545 4.36001 43.1865 3.54401 43.6505 2.80801C44.1145 2.07201 44.7705 1.49601 45.6185 1.08001C46.4665 0.648007 47.4265 0.432007 48.4985 0.432007C50.0505 0.432007 51.2745 0.800007 52.1705 1.53601C53.0665 2.25601 53.6825 3.10401 54.0185 4.08001L51.1625 5.28001C50.9865 4.75201 50.6745 4.31201 50.2265 3.96001C49.7945 3.59201 49.2345 3.40801 48.5465 3.40801C47.8425 3.40801 47.2585 3.57601 46.7945 3.91201C46.3465 4.23201 46.1225 4.65601 46.1225 5.18401C46.1225 5.69601 46.3385 6.13601 46.7705 6.50401C47.2025 6.85601 47.9145 7.20001 48.9065 7.53601L49.9865 7.89601C51.4745 8.40801 52.6105 9.08801 53.3945 9.93601C54.1945 10.768 54.5945 11.864 54.5945 13.224C54.5945 14.344 54.3065 15.296 53.7305 16.08C53.1545 16.848 52.4105 17.424 51.4985 17.808C50.6025 18.192 49.6745 18.384 48.7145 18.384Z" fill="#0788FF"/>
  </svg>
  <ng-container *ngIf="process.user?.id && !showAlert">
    <div class="step-button">
      <span class="hc-subheader__title" *ngIf="process.user?.id">
        <a class="history-button" [ngClass]="{'history-button--grey': process.workflow || process.step}" (click)="changeWorkflow()">
            {{ process.user?.firstname }} {{ process.user?.lastname }}
        </a>
      </span>
      <span class="hc-subheader__title" *ngIf="process.workflow?.id" (click)="changeStep()">
        <a class="history-button" [ngClass]="{'history-button--grey': process.step}">
           {{ process.workflow?.title | uiLabel }}
        </a>
      </span>
      <span class="hc-subheader__title" *ngIf="process.step?.title">
        <a class="history-button">
          {{ process.step?.title | uiLabel }}
        </a>
      </span>
    </div>
  </ng-container>
</header>

<div class="hc-content">
  <div class="hc-steps hc-content__container" *ngIf="!process.user?.id || !process.workflow?.id || !process.step?.id">
    <div class="hc-content__item" *ngIf="!process.user?.id">
      <h4 class="hc-steps__title">{{ 'process.selectUser' | transloco }}</h4>
      <app-user-select [userId]="params.userId" (userChange)="onUserChanged($event)"></app-user-select>
    </div>

    <div class="hc-content__item" *ngIf="process.user?.id && !process.workflow?.id">
      <h4 class="hc-steps__title">{{ 'process.selectProcess' | transloco }}</h4>

      <app-workflow-select [brandId]="store.brandId" [workflowId]="params.workflowId" (workflowChange)="onWorkflowChanged($event)"></app-workflow-select>
    </div>

    <div class="hc-content__item" *ngIf="process.user?.id && process.workflow?.id && !process.step?.id">
      <h4 class="hc-steps__title">{{ 'process.chooseStep' | transloco }}</h4>

      <app-step-select [stepId]="params.stepId" [workflowId]="params.workflowId" [process]="process" (stepChange)="onStepChanged($event)" ></app-step-select>
    </div>
  </div>

  <div class="hc-process" *ngIf="process.user?.id && process.workflow?.id && process.step?.id">
    <div class="hc-process__data">
      <div class="hc-info">
        <h4 class="hc-info__title">{{ 'process.recipe' | transloco }}</h4>

        <div class="hc-ftable">
          <div class="hc-ftable__header">
            <div class="hc-ftable__cell">{{ 'process.product' | transloco }}</div>
            <div class="hc-ftable__cell">{{ 'process.number' | transloco }}</div>
            <div class="hc-ftable__cell">{{ 'process.store' | transloco }}</div>
          </div>
        </div>

        <div class="hc-info__container">
          <div class="hc-ftable" *ngIf="groupedRecipes.length && !isLoading.recipes">
            <div class="hc-ftable__body" [ngClass]="{'green-body': item.isModified }" *ngFor="let item of groupedRecipes">
              <div class="hc-ftable__row" *ngFor="let reciep of item.recipes">
                <div class="hc-ftable__cell">
                  {{ reciep.title | uiLabel }}
                </div>

                <div class="hc-ftable__cell">
                  {{ reciep.netAmount * processObj.totalAmount | fixed:5 }} {{ reciep.recipeUnitOfMeasure | uiLabel | converter }}
                </div>

                <div class="hc-ftable__cell">
                  <span *ngIf="!reciep.unlimited;else unlimitedContent">
                    {{ reciep.balanceAmount }} {{ reciep.balanceUnitOfMeasure | uiLabel | converter }}
                  </span>

                  <ng-template #unlimitedContent>
                    <small>{{ 'process.unlimited' | transloco }}</small>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!groupedRecipes.length && isLoading.recipes">
            {{ 'process.success' | transloco }}
          </div>

          <div *ngIf="!groupedRecipes.length && !isLoading.recipes">
            {{ 'process.fatal' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <div class="hc-process__info">
      <h1 class="hc-process__line">
        {{ process.step?.title | uiLabel }}
      </h1>

      <div class="hc-process__adder">
        <div class="hc-process__line">
          <button type="button" class="hc-button hc-button--success hc-process__toggler hc-disable-dbl-tap-zoom" (click)="addItem()">
            <img src="/assets/images/plus.svg" alt=""></button>
        </div>
        <div class="hc-process__line">
          <input type="number" class="hc-input hc-process__number" placeholder="0" [(ngModel)]="processObj.totalAmount">
        </div>
        <div class="hc-process__line">

        </div>
        <div class="hc-process__line">
          <button type="button" class="hc-button hc-button--danger hc-process__toggler hc-disable-dbl-tap-zoom" (click)="removeItem()">
              <img src="/assets/images/minus.svg" alt="">
          </button>
        </div>

        <div class="hc-process__line">
          {{ 'process.num' | transloco }}
        </div>
      </div>

      <div class="hc-process__line_top">
        <button *ngIf="!showAlert" type="button" class="hc-button hc-button--success hc-process__btn hc-disable-dbl-tap-zoom"
                [disabled]="isLoading.submit" (click)="submitData()">
          {{isLoading.submit ? 'Отправляется...' : 'Отправить'}}</button>
      </div>
    </div>

    <div class="hc-process__data">
      <div class="hc-info">
        <h4 class="hc-info__title">{{ 'process.product' | transloco }}</h4>
        <div class="hc-ftable">
          <div class="hc-ftable__header">
            <div class="hc-ftable__cell">{{ 'process.product' | transloco }}</div>
            <div *ngIf="isCfOutput" class="hc-ftable__cell">{{ 'process.invoice' | transloco }}</div>
            <div class="hc-ftable__cell">{{ 'process.store' | transloco }}</div>
          </div>
        </div>

        <div class="hc-info__container">
          <div class="hc-ftable" *ngIf="outputRecipes.length && !isLoading.outputRecipes">
            <div class="hc-ftable__body">
            <div class="hc-ftable__row" *ngFor="let reciep of outputRecipes">
                <div class="hc-ftable__cell">
                  {{ reciep.title | uiLabel }}
                </div>

                <div *ngIf="isCfOutput" class="hc-ftable__cell">
                  {{ reciep.invoiceAmount }} {{ reciep.recipeUnitOfMeasure | uiLabel | converter }}
                </div>

                <div class="hc-ftable__cell">
                  <span *ngIf="!reciep.unlimited;else unlimitedContent">
                    {{ reciep.balanceAmount }} {{ reciep.balanceUnitOfMeasure | uiLabel | converter }}
                  </span>

                  <ng-template #unlimitedContent>
                    <small>{{ 'process.unlimited' | transloco }}</small>
                  </ng-template>
                </div>
            </div>
            </div>
          </div>

          <div *ngIf="!groupedRecipes.length && isLoading.recipes">
            {{ 'process.success' | transloco }}
          </div>

          <div *ngIf="!groupedRecipes.length && !isLoading.recipes">
            {{ 'process.fatal' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <div class="hc-alert" *ngIf="showAlert">
      <h2 class="hc-alert__content">
        <div class="hc-alert__icon"></div>

        <div class="hc-alert__text">
          {{ 'process.addData' | transloco }}
        </div>
      </h2>
    </div>
  </div>
  <div *ngIf="process.step?.description" [ngClass]="{'modal-content': true, 'process-content': true, 'modal-content-show': showModal}">
    <div [ngClass]="{'process-modal__content': true}" (click)="changeModal()">
      <p>{{ this.showModal  ? '▼' : '▲' }}</p>
    </div>
    <div [innerHTML]="process.step.description | safeHtml"></div>
  </div>
  <div [ngClass]="{'shadow': showModal}" (click)="changeModal()"></div>
</div>
