import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProcessService } from '@confectionary-app/process/process.service';
import { Process } from '@confectionary-app/process/process';
import { UserBasicModel, WorkflowModel, WorkflowStepModel } from 'hx-services';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-step-select',
  templateUrl: './step-select.component.html',
  styleUrls: ['./step-select.component.css']
})
export class StepSelectComponent implements OnInit, OnDestroy {
  @Input() workflowId!: number;
  @Input() stepId?: number;
  @Input() process?: {
    user?: UserBasicModel;
    workflow?: WorkflowModel;
    step?: WorkflowStepModel;
  };
  @Output() stepChange = new EventEmitter<WorkflowStepModel | undefined>();
  workflowSteps: WorkflowStepModel[] = [];
  isLoading = {
    list: false,
    submit: false
  };
  processObj: Partial<Process> = {
    totalAmount: 1,
  };
  showAlert = false;
  timer: any;

  constructor(
    private processService: ProcessService,
    private toastr: HxToastrService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.loadList();
  }

  ngOnDestroy() {
    this.destroyTimer();
  }

  selectStep(item: WorkflowStepModel) {
    this.stepChange.emit(item);
  }

  addItem() {
    this.processObj.totalAmount += 1;
    if (this.processObj.totalAmount === 0) {
      this.processObj.totalAmount = 1;
    }
  }

  removeItem() {
    this.processObj.totalAmount -= 1;
    if (this.processObj.totalAmount === 0) {
      this.processObj.totalAmount = -1;
    }
  }

  async submitData() {
    if ((this.processObj.totalAmount ?? 0) === 0) {
      return this.toastr.warning(this.tr.translate('step-select.addProducts'));
    }
    this.isLoading.submit = true;
    this.processObj.userId = this.process.user.id;
    this.processObj.workflowId = this.process.workflow.id;
    try {
      await this.processService.sendProcessWorkflow(this.processObj);
      this.showAlert = true;
      this.resetProcess();
    } finally {
      this.isLoading.submit = false;
    }
  }

  changeData() {
    this.processObj = {totalAmount: 1};
    this.destroyTimer();
  }

  private resetProcess() {
    this.timer = setTimeout(() => {
      this.showAlert = false;
      this.changeData();
    }, 1000);

    this.processObj = {totalAmount: 1};
  }

  private destroyTimer() {
    if (!this.timer) {
      return;
    }

    this.showAlert = false;
    clearTimeout(this.timer);
  }

  private loadList() {
    this.isLoading.list = true;
    this.processService.getWorkflowStep(this.workflowId).subscribe({
      next: result => {
        this.workflowSteps = result.list;

        if (this.stepId) {
          const item = this.workflowSteps.find(el => el.id === this.stepId);
          this.selectStep(item);
        }
        this.isLoading.list = false;
      },
      error: () => this.isLoading.list = false
    });
  }

  private errorHandler(err) {
    const error = err.error;
    if (error.code === 567) {
      if (error.data.products && error.data.products.length) {
        let message = '';
        let list = '';
        error.data.products.forEach((element, index) => {
          list += index > 0 ? `, ${element}` : `${element}`;
        });

        message = this.tr.translate('step-select.ts.ingredientRequired', {list: list});

        this.toastr.error(message);
      }
    } else {
      this.toastr.warning(error.message);
    }
  }
}
