import { Component, Input } from '@angular/core';
import { OrderRowModel } from 'hx-services';

@Component({
  selector: 'hc-order-icon',
  templateUrl: './hc-order-icon.component.html',
  styles: [
      `
      .delivery {
        margin-bottom: 5px;
      }

      .delivery hc-icon {
        margin: 0 0.1rem;
      }
    `
  ]
})
export class HcOrderIconComponent {
  @Input()
  set order(val: OrderRowModel) {
    this.isDelivery = !!val.deliveryRangeTime;
    this.hasDecor = val.decorExists;
    this.hasDecorText = !!val.decorText && !this.hasDecor;
    this.hasNote = !!val.note;
  }

  isDelivery = false;
  hasDecorText = false;
  hasDecor = false;
  hasNote = false;
}
