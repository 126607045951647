<h4 class="title">{{ 'invoice-events.title' | transloco }}</h4>

<table class="hc-table hc-table--striped hc-table--font_s" *ngIf="events.length && !isLoading.list">
  <thead class="hc-table__head">
    <tr class="hc-table__row hc-table__row--head">
      <th class="hc-table__cell">{{ 'table.head.date' | transloco }}</th>
      <th class="hc-table__cell">{{ 'table.head.author' | transloco }}</th>
      <th class="hc-table__cell">{{ 'table.head.note' | transloco }}</th>
      <th class="hc-table__cell">{{ 'table.head.status' | transloco }}</th>
    </tr>
  </thead>

  <tbody class="hc-table__body">
    <tr  class="hc-table__row" *ngFor="let even = even; let event of events" [ngClass]="{ 'hc-table__row--even': even }">
      <td class="hc-table__cell">{{ event.date | date: 'dd.MM HH:mm' }}</td>
      <td class="hc-table__cell">
        <span [title]="event.actorFullName + ' - ' + event.actor">{{ event.actorFullName }}</span>
      </td>
      <td class="hc-table__cell">{{ event?.info }}</td>
      <td class="hc-table__cell">
        <span class="m-badge m-badge--{{invoiceEventList[event.type]?.badge }} m-badge--wide m-badge--rounded">
          {{ 'invoice.type.' + event.type | transloco }}
        </span>
      </td>
    </tr>
  </tbody>
</table>

<h4 *ngIf="!events.length && isLoading.list">
  {{ 'invoice-events.loader.success' | transloco }}
</h4>

<h4 *ngIf="!events.length && !isLoading.list">
  {{ 'invoice-events.loader.fatal' | transloco }}
</h4>
