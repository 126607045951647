<div class="hc-subheader">
  <div class="hc-subheader__row">
    <header class="hc-subheader__container">
      <img src="/assets/images/logo.png" class="is--logo" width="104" alt="happy cake logo">
    </header>

    <div class="hc-subheader__title">
      <span>
        <a class="hc-link--pseudo">
          / {{ 'hc-store-checker.storeCheck' | transloco }} /
        </a>
      </span>
    </div>
  </div>
</div>

<div class="hc-content">
  <div class="hc-content__container">
    <div class="hc-content__item">
      <div class="alert alert-danger" *ngIf="showError">
        {{ 'hc-store-checker.noAccess' | transloco }}
      </div>

      <form (submit)="onSubmitForm()" class="code-form">
        <input type="tel" autocomplete="something" [(ngModel)]="code" name="code" class="hc-input" [placeholder]="'hc-store-checker.enterCode' | transloco">

        <button type="submit" class="hc-button hc-button--success hc-button--l">{{ 'buttons.send' | transloco }}</button>
      </form>
    </div>
  </div>
</div>
