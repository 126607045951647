import { Component } from '@angular/core';
import { OhcService } from '@confectionary-app/service/ohc.service';
import { Router } from '@angular/router';
import { HxToastrService } from 'hx-component';
import { HxAuthService } from 'hx-services';

@Component({
  selector: 'hc-store-checker',
  templateUrl: './hc-store-checker.component.html',
  styleUrls: ['./hc-store-checker.component.css']
})
export class HcStoreCheckerComponent {
  code: string;
  showError = false;

  constructor(
    private ohcService: OhcService,
    private router: Router,
    private auth: HxAuthService,
    private toastr: HxToastrService,
  ) { }

  onSubmitForm() {
    if (!this.code) {
      return;
    }
    this.showError = false;

    this.ohcService.sendCode(this.code).subscribe(result => {
      this.canLogin(result.id);
    }, err => {
      if (err.error) {
        if (err.error.code === 575) {
          this.toastr.error(`${err.error.message}`);
        }
      }
    });
  }

  private canLogin(storeId: number) {
    this.showError = !this.auth.hasStoreMembership(storeId);

    if (!this.showError) {
      this.ohcService.setStoreId(storeId);
      this.router.navigateByUrl('/');
    }
  }

}
