import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { InvoiceModel, PagedList } from '../interface';
import { InvoiceStatus, InvoiceType } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class HxInvoiceService {
  constructor(
    private http: HttpClient,
  ) {
  }

  rejectBalance(invoiceId: number, reason: string): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/${invoiceId}/rejected`, {reason: reason});
  }

  getInvoiceById(invoiceId: number): Observable<InvoiceModel> {
    return this.http.get<InvoiceModel>(`/api/vanilla/invoices/${invoiceId}`);
  }

  discardBalance(request: DiscardBalanceRequest): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/discard`, request);
  }

  getInvoiceList(params?: {
    storeId?: number,
    date?: string,
    statuses?: InvoiceStatus[],
    productInfoIds?: number[],
    limit?: number,
    page?: number,
    supplierId?: number,
  }): Observable<PagedList<InvoiceModel>> {
    return this.http.get<PagedList<InvoiceModel>>(`/api/vanilla/invoices`, {params: toHttpParams(params, true)});
  }

  createInvoice(body: CreateInvoiceRequest): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices`, body);
  }

  cancelInvoice(invoiceId: number, reason: string): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/${invoiceId}/cancelled`, {reason: reason});
  }

  acceptInvoice(invoiceId: number): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/${invoiceId}/received`, {});
  }

  sendPartially(id: number, products: InvoiceProductAmountModel[]): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/${id}/send-partially`, products);
  }

  sendInvoice(id: number): Observable<void> {
    return this.http.post<void>(`/api/vanilla/invoices/${id}/sent`, {});
  }
}

export interface DiscardBalanceRequest {
  storeId: number;
  reason: string;
  receiveUserId: number;
  fileIds: number[];
  products: { productInfoId: number, amount: number }[];
  invoiceType: InvoiceType;
}

export interface CreateInvoiceRequest {
  toStoreId: number;
  description: string;
  products: InvoiceProductAmountModel[];
}

export interface InvoiceProductAmountModel {
  productInfoId: number;
  amount: number;
}
