<div class="hc-subheader">
  <h3 class="hc-subheader__title">
    {{ 'order-stats.title' | transloco }}
    <i *ngIf="isLoading.calendarEvents" class="la la-refresh la-spin"></i>
  </h3>
</div>

<div class="hc-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="hc-filter__block" *ngIf="retailOutlets && retailOutlets.length > 0">
        <ng-container *ngFor="let retailOutlet of retailOutlets">
          <button type="button" class="hc-button hc-button--tag" [ngClass]="{'is--selected': retailOutlet.selected, 'is--notselected': !retailOutlet.selected}"
                  (click)="toggleRetailOutlet(retailOutlet)">
            <i class="la la-check"></i>
            <span>{{ retailOutlet.title | uiLabel }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="hc-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body" *ngIf="calendarOptions">
          <div class="hx-filters">
            <div class="filter">
              <hx-date-range-select [(ngModel)]="params.date" (selectChange)="onDateChanged()"></hx-date-range-select>
            </div>
          </div>
          <br>
          <br>
          <div class="row">
            <full-calendar [options]="calendarOptions" #calendar></full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'order-stats.modalTitle' | transloco }} {{ getCategoryTitle() }}</h3>
    <button class="modal-close" type="button" (click)="modal.dismiss()">
      <span>⨯</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="m_datatable m-datatable m-datatable--default m-datatable--loaded">
      <table *ngIf="list.length && !isLoading.list" class="hc-table hc-table--striped">
        <thead class="hc-table__head">
          <tr class="hc-table__row hc-table__row--head">
            <th class="hc-table__cell">
              <span>{{ 'order-stats.table.head.product' | transloco }}</span>
            </th>
            <th class="hc-table__cell text-right">
              <span>{{ 'order-stats.table.head.plan' | transloco }}</span>
            </th>
            <th class="hc-table__cell text-right">
              <span>{{ 'order-stats.table.head.reserve' | transloco }}</span>
            </th>
            <th *ngIf="isToday()" class="hc-table__cell text-right">
              <span>{{ 'order-stats.table.head.balance' | transloco }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="hc-table__body">
          <ng-container *ngFor="let even = even;let i = index; let productItem of list">
            <ng-container *ngIf="(productItem.reservedDelivery + productItem.reservedPickUp) > 0 || (productItem.balance || productItem.limit)">
              <tr class="hc-table__row" [ngClass]="{ 'hc-table__row--even': even }">
                <td class="hc-table__cell">
                  <a routerLink="detail"
                     [queryParams]="{id: productItem.productInfo?.id, date: isoDate(selectedDate?.getTime()), storeIds: params.storeIds.join(',') }"
                     (click)="modal.dismiss()">
                    {{ productItem.productInfo?.title | uiLabel }}
                  </a>
                </td>
                <td class="m-datatable__cell text-right">
                  {{ productItem.plan }}
                </td>
                <td class="m-datatable__cell text-right">
                  {{ (productItem.reservedDelivery + productItem.reservedPickUp) | number }}
                </td>
                <td *ngIf="isToday()" class="hc-table__cell text-right">
                  <span *ngIf="isToday()">
                    {{ productItem.balance }}
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <h4 *ngIf="!list.length && isLoading.list">
        {{ 'order-stats.loader.success' | transloco }}
      </h4>

      <h4 class="m--margin-top-20 text-center" *ngIf="!list.length && !isLoading.list">
        {{ 'order-stats.loader.fatal' | transloco }}
      </h4>
    </div>
  </div>
</ng-template>
