<ul class="hc-proccess-select">
  <li class="hc-proccess-select__item" *ngFor="let item of users" (click)="selectUser(item)">
    <div class="hc-proccess-select__name">
      <div>
        {{ item.firstname }} {{ item.lastname }}
      </div>

      <div class="hc-proccess-select__position">
        {{ item.position }}
      </div>
    </div>
  </li>

  <li *ngIf="!users.length && !isLoading">
    {{ 'user-select.fatal' | transloco }}
  </li>

  <li *ngIf="!users.length && isLoading">
    {{ 'user-select.success' | transloco }}
  </li>
</ul>
