<section class="hc-order" *ngIf="order">
  <header class="hc-order__header">
    <a (click)="goBack()" class="hc-order__back">
      <hc-icon icon="arrow"></hc-icon>
    </a>

    <h3 class="hc-order__title">
      №{{order.uniqueNumber}} {{ 'order-view.from' | transloco }} {{ order.createDate | date: 'dd.MM.yyyy / HH:mm' }}
    </h3>

    <button type="button" *ngIf="canDecorated()" (click)="decorOrder()" class="is--right hc-button hc-button--success">
      <span>{{ 'buttons.checkout' | transloco }}</span>
    </button>
  </header>

  <div class="hc-order__content hc-container">
    <div class="hc-container__block">
      <div class="hc-container__header">
        <h4 class="hc-container__title hc-container__title--reset">{{ 'order-view.informationOrder' | transloco }}</h4>

        <hc-tag [action]="order.action"></hc-tag>
      </div>


      <div class="is--responsive-table">
        <table class="hc-table">
          <thead class="hc-table__head">
            <tr class="hc-table__row">
              <th class="hc-table__cell">{{ 'order-view.table.head.client' | transloco }}</th>
              <th class="hc-table__cell">{{ 'order-view.table.head.address' | transloco }}</th>
              <th class="hc-table__cell">
                <span *ngIf="order.deliveryRange">
                  {{ 'order-view.table.head.timeDelivery' | transloco }}
                </span>
                <span *ngIf="!order.deliveryRange">
                  {{ 'order-view.table.head.pickupTime' | transloco }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody class="hc-table__body">
            <tr class="hc-table__row">
              <td class="hc-table__cell">
                <div *ngIf="order.phone && order.phone !== order.client?.phone" [title]="'order-view.table.body.numberOrderS' | transloco">{{ order.phone }},
                </div>
                {{ order.client?.phone }}
                <br> {{ order.client?.fullname }}
              </td>
              <td class="hc-table__cell">
                <hc-icon [icon]="order.deliveryType"></hc-icon>

                <span *ngIf="order.deliveryType === 'PICKUP'">
                  <strong>{{ 'order-view.table.body.pickup' | transloco }}</strong>, {{ store?.title | uiLabel }}
                  <br>
                </span>

                {{ city?.title | uiLabel }}, {{ order.address }} <ng-container *ngIf="order.admDiv">({{order.admDiv}})</ng-container>

                <div *ngIf="order.deliveryType === 'DELIVERY'">
                  {{ 'order-view.table.body.fromShop' | transloco }} {{ store?.title | uiLabel }}
                </div>
              </td>

              <td class="hc-table__cell">
                <div style="vertical-align: middle;" *ngIf="!order.deliveryRange?.id"
                  title="Время доставки: {{ order.date | date: 'dd.MM.yyyy HH:mm' }}">
                  <hc-icon icon="clock"></hc-icon>

                  <span>{{ order.date | date: 'dd.MM.yyyy HH:mm' }}</span>
                </div>

                <div style="vertical-align: middle;" *ngIf="order.deliveryRange?.id"
                  title="Время доставки: {{ order.deliveryRange?.date | date: 'dd.MM.yyyy' }}  ({{ order.deliveryRange?.fromTime | time }} - {{ order.deliveryRange?.toTime | time }})">
                  <hc-icon icon="clock"></hc-icon>
                  <span>{{ order.deliveryRange?.date | date: 'dd.MM.yyyy' }}  ({{ order.deliveryRange?.fromTime | time }} - {{ order.deliveryRange?.toTime | time }})</span>
                </div>

                <div *ngIf="order.deliveryUser?.id" title="Курьер">
                  <div>Курьер:</div>
                  <i class="la la-user" *ngIf="order.deliveryUser?.fullname"></i> {{ order.deliveryUser?.fullname }}
                  <br>
                  <i class="la la-phone" *ngIf="order.deliveryUser?.phone"></i> {{ order.deliveryUser?.phone }}
                  <br>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="hc-container__block">
      <h4 class="hc-container__title">Содержимое заказа</h4>

      <div class="is--responsive-table">
        <table class="hc-table">
          <thead class="hc-table__head">
            <tr class="hc-table__row">
              <th class="hc-table__cell">Наименование</th>
              <th class="hc-table__cell is--align-right">Цена</th>
              <th class="hc-table__cell is--align-right">Вес</th>
              <th class="hc-table__cell is--align-right">Количество</th>
              <th class="hc-table__cell is--align-right">Сумма</th>
            </tr>
          </thead>
          <tbody class="hc-table__body">
            <ng-container *ngFor="let product of order.products">
              <ng-container *ngIf="isAvailableProduct(product.status)">
                <tr class="hc-table__row" [ngClass]="{'m--text-line-through': isCancelledProduct(product.status) }">
                  <td class="hc-table__cell">
                    <hc-icon [icon]="product.type"></hc-icon>

                    <span>{{ product.productInfo?.title | uiLabel }}</span>
                    <span *ngIf="product.price?.amount !== 1">{{ product.price?.amount }}</span>

                    <ng-container *ngIf="order.action !== 'CANCELLED' && product.status === 'CANCELLED'">
                      <hc-tag [action]="product.status"></hc-tag>
                    </ng-container>
                  </td>
                  <td class="hc-table__cell is--align-right text-nowrap">
                    {{ product.price?.value | uiCurrency:order.currency }}
                  </td>
                  <td class="hc-table__cell is--align-right text-nowrap">
                    <span *ngIf="showWeight(product)">
                      {{ product.weight | weight }}
                    </span>
                  </td>
                  <td class="hc-table__cell is--align-right">
                    {{ product.amount }}
                  </td>
                  <td class="hc-table__cell is--align-right text-nowrap">
                    {{ product.value | uiCurrency:order.currency }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <tr class="hc-table__row">
              <td class="hc-table__cell">
                <span [ngClass]="{'m--font-bolder m--regular-font-size-lg2': order.total === order.subTotal}">Всего</span>
              </td>
              <td class="hc-table__cell is--align-right"></td>
              <td class="hc-table__cell is--align-right text-nowrap">
                {{ totalGram | weight }}
              </td>
              <td class="hc-table__cell is--align-right">
                {{ totalSAmount }}
              </td>
              <td class="hc-table__cell is--align-right text-nowrap">
                <h4 class="hc-container__title hc-container__title--reset" [ngClass]="{'is--normal': discounts?.length}">
                  {{ order.subTotal | uiCurrency:order.currency }}
                </h4>
              </td>
            </tr>

            <tr *ngIf="discounts.length">
              <td colspan="5" class="hc-table__cell">Скидки:</td>
            </tr>

            <ng-container *ngFor="let discount of discounts">
              <tr [ngClass]="{'is--disabled': !discount.enabled }">
                <td colspan="2" class="hc-table__cell is--left-padding">
                  {{ discount.promoTitle | uiLabel }}
                </td>

                <td class="hc-table__cell">
                </td>

                <td class="hc-table__cell text-right">
                  {{ discount.productAmount }}
                </td>

                <td class="hc-table__cell text-right">
                  {{ discount | discountUoM: order.currency }}
                </td>
              </tr>
            </ng-container>

            <tr class="hc-table__row" *ngIf="order.total >= 0 && order.total < order.subTotal">
              <td class="hc-table__cell">
                <h5 class="hc-container__title hc-container__title--reset">Итого</h5>
              </td>

              <td class="hc-table__cell is--align-right">
              </td>

              <td class="hc-table__cell is--align-right text-nowrap">
                {{ totalGram | weight }}
              </td>

              <td class="hc-table__cell is--align-right">
                {{ totalSAmount }}
              </td>

              <td class="hc-table__cell is--align-right text-nowrap">
                <h4 class="hc-container__title hc-container__title--reset">
                  {{ order.total | uiCurrency: store?.currency }}
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="hc-container__block" *ngIf="decorFileCount > 0">
      <h4 class="hc-container__title">Фото</h4>

      <div class="hc-filelist">
        <div class="hc-filelist__item" *ngFor="let file of order.files">
          <ng-container *ngIf="isDecorFolder(file.folder)">
            <a class="hc-filelist__img" (click)="showImage(file)" [ngStyle]="getImageStyle(file.guid)"></a>

            <div class="hc-filelist__info" [title]="file.fileName">
              {{ file.fileName }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <aside class="hc-order__sidebar hc-container">
    <div class="hc-container__block" *ngIf="order.decorText">
      <h4 class="hc-container__title">Оформление</h4>

      <p>{{ order.decorText }}</p>
    </div>

    <div class="hc-container__block" *ngIf="order.note">
      <h4 class="hc-container__title">Заметка</h4>

      <p>{{ order.note }}</p>
    </div>

    <div class="hc-container__block">
      <h4 class="hc-container__title">История заказа</h4>

      <div class="m-list-timeline">
        <span *ngIf="order.from">
          Заказ от {{ 'from.' + order.from | transloco }}
        </span>

         <app-event-list [id]="order.id"></app-event-list>
      </div>
    </div>
  </aside>
</section>

<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ image?.name }}</h3>

    <button class="modal-close" type="button" (click)="modal.dismiss()">
      <hc-icon icon="delete"></hc-icon>
    </button>
  </div>

  <div class="modal-body text-center">
    <img [src]="image.path" [alt]="image?.name" style="max-width: 100%;">
  </div>
</ng-template>
