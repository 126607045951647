import { Component, OnInit } from '@angular/core';
import { OhcService } from '@confectionary-app/service/ohc.service';
import { HxToastrService } from 'hx-component';
import { HxInvoiceService, InvoiceModel, InvoiceStatus } from 'hx-services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceModalComponent } from '@confectionary-app/modal/invoice/invoice.modal';

interface InvoiceOption {
  id: InvoiceStatus;
  label: string;
  icon?: string;
  selected?: boolean;
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  list: InvoiceModel[] = [];
  isLoading = {
    list: false,
    invoice: false,
  };
  storeId: number;
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 10,
    maxPage: 1
  };
  statusList = {
    'CREATED': {
      badge: 'info'
    },
    'PACKED': {
      badge: 'warning'
    },
    'DECORED': {
      badge: 'focus'
    },
    'SHIPPED': {
      badge: 'warning'
    },
    'RECEIVED': {
      badge: 'success'
    },
    'CANCELLED': {
      badge: 'danger'
    },
    'NEW': {
      badge: 'info'
    },
    'REJECTED': {
      badge: 'danger'
    },
    'SHIPMENT': {
      badge: 'warning'
    },
    'DISCARDED': {
      badge: 'focus'
    },
    'DELIVERED': {
      badge: 'focus'
    },
    'PAID': {
      badge: 'success'
    },
    'COMPLETED': {
      badge: 'success'
    },
    'TAKEN': {
      badge: 'warning'
    },
    'RETURNED': {
      badge: 'metal'
    },
  };
  statuses: InvoiceOption[] = [
    {id: InvoiceStatus.NEW, label: 'создан', selected: false, icon: 'accept'},
    {id: InvoiceStatus.SENT, label: 'отправлен', selected: false, icon: 'accept'},
    {id: InvoiceStatus.ACCEPTED, label: 'принят', selected: false, icon: 'accept'},
    {id: InvoiceStatus.REJECTED, label: 'отклонён', selected: false, icon: 'accept'},
    {id: InvoiceStatus.CANCELLED, label: 'отменён', selected: false, icon: 'accept'},
    {id: InvoiceStatus.DISCARDED, label: 'списан', selected: false, icon: 'accept'}
  ];
  invoiceStatuses: InvoiceStatus[] = [InvoiceStatus.NEW, InvoiceStatus.SENT, InvoiceStatus.ACCEPTED, InvoiceStatus.DISCARDED, InvoiceStatus.REJECTED];
  private defaultStatuses: InvoiceStatus[] = [InvoiceStatus.NEW, InvoiceStatus.ACCEPTED, InvoiceStatus.DISCARDED, InvoiceStatus.REJECTED];

  constructor(
    private invoiceService: HxInvoiceService,
    private toastr: HxToastrService,
    private modalService: NgbModal,
    private ohcService: OhcService,
  ) {
  }

  ngOnInit() {
    this.statuses
      .filter(status => this.defaultStatuses.includes(status.id))
      .forEach(status => {
        status.selected = true;
        status.icon = 'delete';
      });
    this.storeId = this.ohcService.getStoreId();
    this.loadInvoiceList();
  }

  viewInvoice(item: InvoiceModel) {
    if (!this.invoiceStatuses.includes(item.status)) {
      return;
    }
    const modalInstance = this.modalService.open(InvoiceModalComponent, {size: 'lg'});
    modalInstance.componentInstance.invoice = item;
    modalInstance.result.then(() => this.loadInvoiceList(), err => {});
  }

  pageChanged(page: number) {
    this.pagination.currentPage = page;
    this.loadInvoiceList();
  }

  selectStatus(invoiceOption: InvoiceOption) {
    invoiceOption.selected = !invoiceOption.selected;
    invoiceOption.icon = invoiceOption.selected ? 'delete' : 'accept';
    this.getTypeFilter();
  }

  getTypeFilter(): void {
    this.loadInvoiceList();
  }

  private showCommonToastrError() {
    this.toastr.error('Произошла ошибка. Попробуйте обновить страницу и повторить операцию');
  }

  private loadInvoiceList() {
    if (!this.storeId) {
      return this.toastr.error('Магазин не найден, выйдите и войдите заново');
    }
    const selectedStatuses: InvoiceStatus[] = this.statuses.filter(status => status.selected).map(status => status.id);
    this.isLoading.list = true;
    this.invoiceService.getInvoiceList({
      storeId: this.storeId,
      page: this.pagination.currentPage,
      limit: this.pagination.limit,
      statuses: selectedStatuses
    }).subscribe(result => {
      this.list = result.list;
      this.pagination.allItemCount = result.count;
      this.isLoading.list = false;
    }, err => this.showCommonToastrError());
  }
}
