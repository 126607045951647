import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  user = {};

  constructor(
    public translate: TranslocoService,
  ) {
    translate.setDefaultLang('ru');
    translate.setActiveLang('ru');
  }
}
