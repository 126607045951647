import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CityModel,
  DiscountResultModel,
  Folder,
  HxCityService,
  HxOrderService,
  HxStoreService,
  OrderAction,
  OrderResponse,
  ProductInfoType,
  ProductStatus,
  StoreFullModel
} from 'hx-services';
import { Subscription } from 'rxjs';
import { format } from 'date-fns';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Component view order info
 */
@Component({
  selector: 'app-order-view.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  id: number;
  order: OrderResponse;
  city: CityModel;
  showTime = 'dd.MM HH:mm';
  baseLink = `/api/vanilla/files/download/`;
  image = {
    name: null,
    path: null
  };
  isLoading = {
    order: true
  };
  store: StoreFullModel;
  discounts: DiscountResultModel[] = [];
  decorFileCount: number;
  totalSAmount = 0;
  totalGram = 0;
  private sub: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private hxOrderService: HxOrderService,
    private storeService: HxStoreService,
    private router: Router,
    private cityService: HxCityService,
    private toaster: HxToastrService,
    private modal: NgbModal,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.params.subscribe(params => {
      this.id = params['id'] ? Number(params['id']) : undefined;
      this.init();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getImageStyle(fileGuid: string): { 'background-image': string } {
    return {
      'background-image': fileGuid ? `url(${this.baseLink}${fileGuid})` : 'none',
    };
  }

  goBack(): void {
    if (window.history.length > 0) {
      window.history.back();
    } else {
      this.router.navigateByUrl('/orders');
    }
  }

  showImage(file) {
    this.image.name = file.fileName;
    this.image.path = `${this.baseLink}${file.guid}`;

    const modal = this.modal.open(this.templateRef, {size: 'lg'});

    modal.result.then(() => this.resetImage(), () => this.resetImage());
  }

  closeModal(dialog) {
    this.resetImage();
    dialog.close(true);
  }

  decorOrder() {
    this.hxOrderService.decorOrder(this.id).then(() => {
      this.loadOrder();
    });
  }

  isAvailableProduct(productStatus: string): boolean {
    return (this.order.action === 'CANCELLED' && productStatus === 'CANCELLED') || productStatus !== 'CANCELLED';
  }

  isCancelledProduct(productStatus: string): boolean {
    return productStatus === 'CANCELLED';
  }

  showWeight(product: any) {
    return product.weight && product.type !== ProductInfoType.DELIVERY;
  }

  canDecorated(): boolean {
    if (this.order) {
      const action = this.order.action;
      return action !== 'CANCELLED' && action !== 'RECEIVED' && action !== 'SHIPPED' && action !== 'DECORED';
    }
  }

  isDecorFolder(folder: string): boolean {
    return folder === Folder.DECOR;
  }

  private init(): void {
    if (this.id) {
      this.loadOrder();
    } else {
      this.toaster.error(this.tr.translate('order-view.ts.error'));
      this.router.navigateByUrl('/');
    }
  }

  private loadStore(): void {
    this.storeService.getFullStore(this.order.storeId).then(result => {
      this.store = result;
    });
  }

  private resetImage() {
    this.image.name = null;
    this.image.path = '';
  }

  private async loadOrder() {
    this.getDiscounts();
    this.order = undefined;
    this.isLoading.order = true;

    try {
      this.order = await this.hxOrderService.getFullOrderById(this.id);

      const date = this.order.date;
      this.decorFileCount = this.order.files.filter((r) => r.folder === Folder.DECOR).length;
      const time = format(new Date(date), 'HH:ss');
      this.showTime = (time && +time.split(':')[0] > 0) ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
      this.totalCounter();
      this.loadStore();
      this.loadCity();
    } finally {
      this.isLoading.order = false;
    }
  }

  private totalCounter() {
    // init new variables
    this.totalSAmount = 0;
    this.totalGram = 0;

    if (this.order.products && this.order.products.length) {
      this.order.products.forEach(element => {
        if (element.status !== ProductStatus.CANCELLED || this.order.action === OrderAction.CANCELLED) {
          this.totalSAmount += element.amount;
          this.totalGram += element.weight * element.amount || 0;
        }
      });
    }
  }

  private loadCity(): void {
    this.cityService.getCityById(this.order.cityId).subscribe(result => {
      this.city = result;
    });
  }

  private async getDiscounts() {
    const result = await this.hxOrderService.getDiscountList(this.id);
    this.discounts = result.results ?? [];
  }
}
