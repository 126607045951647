import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

/**
 * Компонент тэгов для статусов заказа.
 * список текущих статусов заказа:
 *  - "CREATED": "создан",
 *  - "MODIFYING": "начал изменение",
 *  - "MODIFIED": "изменён",
 *  - "PACKED": "упакован",
 *  - "DECORED": "оформлен",
 *  - "SHIPPED": "на доставке",
 *  - "RECEIVED": "доставлен",
 *  - "CANCELLED": "отменён",
 *  - "PAID": "оплачен",
 *  - "TAKEN": "взял",
 *  - "RETURNED": "вернул",
 *  - "COMPLETED": "завершён"
 *
 * @export
 * @class HcTagComponent
 */
@Component({
  selector: 'hc-tag',
  template: `
    <span *ngIf="action">{{ 'action.' + action |  transloco }}<ng-content></ng-content></span>
  `,
  styleUrls: ['./hc-tag.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HcTagComponent {
  private _action: string;
  @Input()
  set action(value: string) {
    this._action = value || null;

    if (value) {
      this.setClass(value);
    }
  }
  get action(): string {
    return this._action;
  }

  @HostBinding('attr.class') classNames = 'hc-tag';

  constructor() { }

  private setClass(value: string) {
    this.classNames = `hc-tag hc-tag--${value}`;
  }

}
