<div class="hc-subheader" style="margin: 20px 0;">
  <div class="hc-subheader__col">
    <h3 class="hc-subheader__title">
      {{ 'order-stat-detail.title' | transloco }}
    </h3>

    <a routerLink="../" [queryParams]="{ date:  date }" class="hc-subheader__back">
      <span class="hc-subheader__back-text">
        {{ 'back.order-stat-detail' | transloco}}
      </span>
    </a>
  </div>
</div>

<div class="container">
  <div class="hc-container">
    <div class="table-responsive">
      <table class="hc-table hc-table--brand" *ngIf="list.length && !isLoading.list">
        <thead class="hc-table__head">
          <tr class="hc-table__row hc-table__row--head">
            <th *ngFor="let menuItem of menu" (click)="sortTable(menuItem)" class="hc-table__cell hc-table__cell--sort text-nowrap">
              <span>
                {{ menuItem.title }}
                <i *ngIf="menuItem.field" class="la" [ngClass]="{'la-sort-amount-desc': menuItem.sort === 'desc', 'la-sort-amount-asc': menuItem.sort === 'asc', 'la-sort': !menuItem.sort}"></i>
              </span>
            </th>
            <th class="hc-table__cell">
              <span>{{ 'table.head.status' | transloco }}</span>
            </th>
            <th class="hc-table__cell">
              <span>{{ 'table.head.order' | transloco }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="hc-table__body">
          <tr *ngFor="let even = even; let i = index; let item of list" class="hc-table__row" [ngClass]="{ 'hc-table__row--even': even }">
            <td class="hc-table__cell">
              <a [routerLink]="'/orders/' + item.id" class="hc-table__number">
                {{ item.uniqueNumber }}
              </a>
            </td>
            <td class="hc-table__cell">{{ item.createDate | date:'dd.MM HH:mm' }}</td>
            <td class="hc-table__cell">{{ item.date | date:'dd.MM HH:mm' }}</td>
            <td class="hc-table__cell text-nowrap">
              <hc-order-icon [order]="item"></hc-order-icon>

              <hc-tag [action]="item.action"></hc-tag>
            </td>
            <td class="hc-table__cell">
              <div *ngFor="let cake of item.products">
                <div *ngIf="cake.status !== 'CANCELLED' && item.action !== 'CANCELLED'">
                  {{ cake.title | uiLabel }}
                  <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span>
                </div>

                <div *ngIf="cake.status === 'CANCELLED'" class="is--red">
                  {{ cake.title | uiLabel }}
                  <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span> - {{ cake.amount }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="!list.length && isLoading.list" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
        <div class="m-loader m-loader--brand"></div>
      </div>
    </div>

    <div class="hc-container__404">
      <h4 *ngIf="!list.length && !isLoading.list">
        {{ 'order-stat-detail.error' | transloco }}
      </h4>
    </div>

    <div class="is--align-center hc-pagination" *ngIf="list.length && !isLoading.list">
      <div class="btn-group btn-group-sm">
        <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
          <i class="las la-angle-double-left"></i>
        </button>
        <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
          <i class="las la-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
