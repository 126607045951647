import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HxAuthService, HxCategoryService, HxRecipeService, WorkflowCategoryModel, WorkflowModel } from 'hx-services';

/**
 * Select current workflow
 */
@Component({
  selector: 'app-workflow-select',
  templateUrl: './workflow-select.component.html',
  styleUrls: ['./workflow-select.component.css']
})
export class WorkflowSelectComponent implements OnInit {
  @Input() brandId!: number;
  @Input() workflowId?: number;
  @Output() workflowChange = new EventEmitter<WorkflowModel | undefined>();

  workflows: WorkflowModel[] = [];
  isLoading = false;
  workflowCategories: WorkflowCategoryModel[] = [];
  workflowCategoryId?: number;

  constructor(
    private categoryService: HxCategoryService,
    private recipeService: HxRecipeService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    this.loadWorkflowCategoryList();
  }

  private loadWorkflowCategoryList() {
    this.categoryService.getWorkflowCategories({brandId: this.brandId}).subscribe(result => {
      if (result && result.list && result.list.length > 0) {
        for (let index = 0; index < result.list.length; index++) {
          this.workflowCategories.push(result.list[index]);
        }
        this.workflowCategoryId = result.list[0].id;
        this.loadList();
      }
    });
  }

  private loadList() {
    this.isLoading = true;
    this.workflows = [];

    this.recipeService.getWorkflowList({workflowCategoryId: this.workflowCategoryId, limit: 1000}).subscribe(result => {
      this.workflows = result.list;
      if (this.workflowId) {
        const item = this.workflows.find(el => el.id === this.workflowId);
        this.selectWorkflow(item);
      }

      this.isLoading = false;
    }, err => this.isLoading = false);
  }

  selectWorkflow(item: WorkflowModel) {
    this.workflowChange.emit(item);
  }

  selectTag(model: WorkflowCategoryModel) {
    this.workflowCategoryId = model.id;
    this.loadList();
  }
}
