<div class="hc-proccess__wrap">
  <ul class="hc-proccess-select hc-proccess-select--filter">
    <ng-container *ngIf="!isLoading && workflows.length">
      <li class="hc-proccess-select__item hc-proccess-select__item--center" *ngFor="let item of workflows" (click)="selectWorkflow(item)">
        <svg class="icon icon--outline" width="58" height="41" viewBox="0 0 58 41" fill="#0788FF" xmlns="http://www.w3.org/2000/svg">
          <path d="M29 40.9999C21.4837 40.9999 14.4112 39.727 9.0551 37.418C3.22551 34.8721 0 31.3494 0 27.4714V13.4693H3.55102V27.4714C3.55102 32.2079 13.9969 37.4772 29 37.4772C44.0031 37.4772 54.449 32.2079 54.449 27.4714V13.4693H58V27.4714C58 31.379 54.7745 34.9017 48.9449 37.418C43.5888 39.727 36.5163 40.9999 29 40.9999Z" fill="#0788FF"/>
          <path d="M29 27.0866C21.4837 27.0866 14.4112 25.8137 9.0551 23.5047C3.22551 20.9588 0 17.4361 0 13.5581C0 9.65054 3.22551 6.1278 9.0551 3.61155C14.4112 1.27292 21.4837 0 29 0C36.5163 0 43.5888 1.27292 48.9449 3.58195C54.7745 6.0982 58 9.65054 58 13.5285C58 17.4361 54.7745 20.9588 48.9449 23.4751C43.5888 25.8137 36.5163 27.0866 29 27.0866ZM29 3.55235C13.9969 3.55235 3.55102 8.82166 3.55102 13.5581C3.55102 18.2946 13.9969 23.5639 29 23.5639C44.0031 23.5639 54.449 18.2946 54.449 13.5581C54.449 8.82166 44.0031 3.55235 29 3.55235Z" fill="#0788FF"/>
        </svg>
        <div class="hc-proccess-select__name">
          <div>
            {{ item.title | uiLabel }}
          </div>
        </div>
      </li>
    </ng-container>

    <li *ngIf="!workflows.length && !isLoading">
      {{ 'workflow-select.fatal' | transloco }}
    </li>

    <li *ngIf="!workflows.length && isLoading">
      {{ 'workflow-select.success' | transloco }}
    </li>
  </ul>
  <div class="hc-proccess__filter hc-filter">
    <div class="hc-filter__list">
      <div *ngFor="let item of workflowCategories" class="hc-filter__item" [ngClass]="{'isSelected': workflowCategoryId === item.id}" (click)="selectTag(item)">
        {{ item.title | uiLabel }}
      </div>
    </div>
  </div>
</div>

