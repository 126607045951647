<div class="container container--top">
  <div class="hc-container">
    <div class="hc-filter">
      <div class="hc-filter__col">
        <div class="hc-filter__row">
          <div class="hc-filter__block">
            <form class="hc-form" (ngSubmit)="getTypeFilter()">
              <div class="hc-input-group">
                <input type="text" required [(ngModel)]="query" name="searchText" class="hc-input hc-input--s"
                  [placeholder]="'order-list.searchOrder' | transloco">

                <button class="hc-button" type="submit">
                  <hc-icon icon="search"></hc-icon>
                </button>
              </div>
            </form>
          </div>

          <div class="hc-filter__block hc-filter__block--s">
            <hx-date-select [(ngModel)]="date" (selectChange)="getTypeFilter()"></hx-date-select>
          </div>

          <div class="hc-filter__block hc-filter__block--s">
            <div class="hc-wrap-select">
              <select [(ngModel)]="decor" (ngModelChange)="getTypeFilter()" name="decor" class="hc-input hc-select hc-input--s">
                <option value="">{{ 'order-list.chooseDesign' | transloco }}</option>
                <option *ngFor="let element of decorList" [value]="element">
                  {{ 'decor.' + element | transloco }}
                </option>
              </select>

              <hc-icon icon="arrows"></hc-icon>
            </div>
          </div>
        </div>

        <div class="hc-filter__row">
          <div class="hc-filter__block" style="width: 134%;">
            <ng-container *ngFor="let item of types">
              <button type="button" class="hc-button hc-button--tag" [ngClass]="{'is--selected': item.selected, 'is--notselected': !item.selected}"
                      (click)="toggleType(item)">
                <i class="la la-check"></i>
                <span>{{ item.label | transloco | lowercase }}</span>
              </button>
            </ng-container>
          </div>

          <div class="hc-filter__block hc-filter__block--s">
            <div class="hc-wrap-select">
              <select [(ngModel)]="deliveryType" (ngModelChange)="getTypeFilter()" name="deliveryType"
                class="hc-input hc-select hc-input--s">
                <option *ngFor="let element of delType" [value]="element.status">{{ element.title }}</option>
              </select>

              <hc-icon icon="arrows"></hc-icon>
            </div>
          </div>
        </div>

        <div class="hc-filter__row">
          <div class="hc-filter__block hc-filter__block--s">
            <label class="m-checkbox m-checkbox--text">
              <input type="checkbox" [(ngModel)]="decored" (ngModelChange)="getTypeFilter()"> {{ 'order-list.decorated' | transloco }}
              <span></span>
            </label>
          </div>

          <div class="hc-filter__block" *ngIf="retailOutlets && retailOutlets.length > 0">
            <ng-container *ngFor="let retailOutlet of retailOutlets">
              <button type="button" class="hc-button hc-button--tag" [ngClass]="{'is--selected': retailOutlet.selected, 'is--notselected': !retailOutlet.selected}"
                      (click)="toggleRetailOutlet(retailOutlet)" [ngbTooltip]="'order-list.tradePoint' | transloco">
                <i class="la la-check"></i>
                <span>{{ retailOutlet.title | uiLabel }}</span>
              </button>
            </ng-container>
          </div>

          <div class="hc-filter__block text-right">
            <button (click)="resetFilter()" class="hc-button hc-button--action">
              <hc-icon icon="reset"></hc-icon>
              <span style="margin-left: 1rem;">{{ 'button.reset' | transloco }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hc-container">
    <div class="table-responsive" *ngIf="list.length && !isLoading.list">
      <table class="hc-table hc-table--brand">
        <thead class="hc-table__head">
          <tr class="hc-table__row">
            <th class="hc-table__cell text-nowrap" (click)="sortTable(menuItem)" *ngFor="let menuItem of menu">
              <span>
                {{ 'menu.' + menuItem.field | transloco }}
                <i class="la"
                  [ngClass]="{'la-sort-amount-desc': menuItem.sort === 'desc', 'la-sort-amount-asc': menuItem.sort === 'asc', 'd-none': menuItem.sort === undefined}"></i>
                <i class="fe fe-sort" *ngIf="!menuItem.sort"></i>
              </span>
            </th>

            <th class="hc-table__cell">
              <span>{{ 'table.head.status' | transloco }}</span>
            </th>

            <th class="hc-table__cell">
              <span>{{ 'table.head.order' | transloco }}</span>
            </th>

            <th class="hc-table__cell">
              <span>{{ 'table.head.registration' | transloco }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="hc-table__body">
          <tr class="hc-table__row" *ngFor="let i = index; let item of list">
            <td class="hc-table__cell">
              <a [routerLink]="['/orders', item.id]" class="hc-table__number">
                {{ item.uniqueNumber }}
              </a>
            </td>

            <td class="hc-table__cell" *ngIf="!item.deliveryRangeTime">{{ item.date | date:'dd.MM / HH:mm' }}</td>
            <td class="hc-table__cell" *ngIf="item.deliveryRangeTime">{{ item.date | date:'dd.MM' }} / {{ item.deliveryRangeTime }}</td>

            <td class="hc-table__cell text-nowrap">
              <hc-order-icon [order]="item"></hc-order-icon>

              <hc-tag [action]="item.action">
                <img *ngIf="item.action === 'CREATED' && item.lastModifyEventDate" alt="flag" class="icon-flag" src="/assets/images/flag.svg"/>
              </hc-tag>
            </td>

            <td class="hc-table__cell">
              <div *ngFor="let cake of item.products">
                <div *ngIf="cake.status !== 'CANCELLED' && item.action !== 'CANCELLED'">
                  {{ cake.title | uiLabel }} <span *ngIf="cake.priceAmount !== 1">{{cake.priceAmount}}</span>
                  —
                  <span *ngIf="cake.type === 'PRODUCT'">{{ cake.weight | weight }} ×</span> {{ cake.amount }} {{ 'order-list.num' | transloco }}
                </div>
              </div>
            </td>

            <td class="hc-table__cell" style="max-width: 300px;">{{ item.decorText }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-body" *ngIf="!list.length && !isLoading.list">
      <h4 class="text-center">
        {{ 'order-list.noDate' | transloco }}
      </h4>
    </div>

    <div *ngIf="!isLoading.list" class="is--align-center hc-pagination">
      <div class="btn-group btn-group-sm">
        <button (click)="seekPrev()" [disabled]="(!seek.hasNext && seek.dir === 'prev') || !seek.val" class="btn btn-sm btn-primary">
          <i class="las la-angle-double-left"></i>
        </button>
        <button (click)="seekNext()" [disabled]="!seek.hasNext && seek.dir === 'next'" class="btn btn-sm btn-primary">
          <i class="las la-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
