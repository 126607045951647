import { Component } from '@angular/core';
import { HxAuthService } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    public auth: HxAuthService,
    public translate: TranslocoService,
  ) {
    // translate.setDefaultLang('ru');
    // translate.setActiveLang('ru');
  }
}
