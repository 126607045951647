import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HxToastrService } from 'hx-component';
import {
  DeliveryType,
  HxAuthService,
  HxOrderService,
  HxStoreService,
  OrderAction,
  OrderRowModel,
  SeekDirection,
  SortType,
  StoreBasicModel,
  toRouterQueryParams
} from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

interface ActionOption {
  id: OrderAction;
  label: string;
  selected?: boolean;
  icon?: string;
}

interface RetailOutletModel extends StoreBasicModel {
  selected?: boolean;
  icon?: string;
}

interface MenuItem {
  field: 'id' | 'number' | 'date';
  sort?: SortType;
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit, OnDestroy {
  list: OrderRowModel[] = [];
  delType = [
    {
      status: '',
      title: 'Тип доставки'
    },
    {
      status: 'PICKUP',
      title: 'Самовывоз'
    },
    {
      status: 'DELIVERY',
      title: 'Курьером'
    }
  ];
  decorList = ['INSCRIPTION', 'DECORED'];
  types: ActionOption[] = [
    {id: OrderAction.CREATED, label: 'action.CREATED', selected: true},
    {id: OrderAction.PACKED, label: 'action.PACKED', selected: true},
    {id: OrderAction.DECORED, label: 'action.DECORED', selected: true},
    {id: OrderAction.SHIPPED, label: 'action.SHIPPED'},
    {id: OrderAction.RECEIVED, label: 'action.RECEIVED'},
    {id: OrderAction.CANCELLED, label: 'action.CANCELLED'},
    {id: OrderAction.REFUNDED, label: 'action.REFUNDED'}
  ];
  menu: MenuItem[] =  [
    {
      sort: SortType.desc,
      field: 'number'
    },
    {
      field: 'date'
    }
  ];
  isLoading = {
    list: false,
  };
  // query params
  decor?: 'INSCRIPTION' | 'DECORED';
  deliveryType?: DeliveryType;
  query?: string;
  date?: string;
  orderBy?: 'id' | 'number' | 'date' = 'id';
  sort = SortType.desc;
  decored = false;
  retailOutlets: RetailOutletModel[] = [];
  storeIds: number[] = [];
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};
  // query params end
  private intervalUpdater?: NodeJS.Timeout;
  private sub: Subscription;

  constructor(
    private orderService: HxOrderService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: HxToastrService,
    private storeService: HxStoreService,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    this.storeIds = [];
    this.retailOutlets = [];
    this.getRetailOutlets();
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      const asNumberFn = (name: string, def?: number) => paramMap.get(name) ? Number(paramMap.get(name)) : def;
      const asBooleanFn = (name: string, def?: boolean) => paramMap.get(name) ? paramMap.get(name) === 'true' : def;
      const asArrFn = (name: string, mapFn: (key: string) => any) => paramMap.get(name) ? paramMap.get(name).split(',').map(mapFn) : [];
      this.decor = paramMap.get('decor') as any ?? 'DECORED';
      this.deliveryType = paramMap.get('deliveryType') as DeliveryType;
      this.query = paramMap.get('query');
      this.date = paramMap.get('date') ?? undefined;
      const orderByParam = paramMap.get('orderBy') ?? 'id';
      this.orderBy = orderByParam && ['id', 'number', 'date'].includes(orderByParam) ? orderByParam as any : undefined;
      this.sort = paramMap.get('sort') as SortType;
      this.decored = asBooleanFn('decored');
      this.seek = {
        dir: paramMap.get('seekDir') as SeekDirection ?? SeekDirection.next,
        val: paramMap.get('seek') ?? undefined,
      };
      const actions = asArrFn('actions', str => OrderAction[str]);
      if (actions.length > 0) {
        this.types.forEach(type => {
          type.selected = actions.includes(type.id);
        });
      }
      this.storeIds = paramMap.get('storeIds') ? paramMap.get('storeIds').split(',').map(id => Number(id)) : this.storeIds;
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    if (this.intervalUpdater) {
      clearInterval(this.intervalUpdater);
    }
  }

  toggleType(type: ActionOption) {
    type.selected = !type.selected;
    this.getTypeFilter();
  }

  toggleRetailOutlet(type: RetailOutletModel) {
    type.selected = !type.selected;
    type.icon = type.selected ? 'delete' : 'accept';
    if (this.storeIds.includes(type.id)) {
      this.storeIds.splice(this.storeIds.indexOf(type.id), 1);
    } else {
      this.storeIds.push(type.id);
    }
    this.getTypeFilter();
  }

  seekPrev() {
    this.navigateUrl({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.navigateUrl({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  async loadList() {
    this.list = [];
    this.isLoading.list = true;
    if (this.retailOutlets.length > 0 && this.storeIds.length === 0) {
      this.isLoading.list = false;
    } else {
      clearInterval(this.intervalUpdater);
      this.intervalUpdater = undefined;
      try {
        const {list, first, last, hasNext} = await this.orderService.getOrderList({
          seek: this.seek.val,
          seekDir: this.seek.dir,
          archived: false,
          activeCart: false,
          limit: 20,
          sort: this.sort,
          orderBy: this.orderBy,
          query: this.query,
          actions: this.getSelectedActions(),
          deliveryType: this.deliveryType,
          date: this.date,
          decor: this.decor,
          decored: this.decored,
          storeIds: this.storeIds,
        });
        this.seek.first = first;
        this.seek.last = last;
        this.seek.hasNext = hasNext;
        this.list = list;
      } catch (err: any) {
        this.toastr.error(this.tr.translate('order-list.ts.error'));
      } finally {
        this.isLoading.list = false;
        this.listUpdater();
      }
    }
  }

  resetFilter() {
    this.orderBy = 'number';
    this.sort = SortType.desc;
    this.deliveryType = undefined;
    this.decor = 'DECORED';
    this.date = undefined;
    this.decored = false;
    this.query = undefined;
    this.storeIds = [];
    const defaultActions = [OrderAction.CREATED, OrderAction.PACKED, OrderAction.DECORED];
    this.types.forEach(type => {
      type.selected = defaultActions.includes(type.id);
    });
    this.retailOutlets.forEach(retailOutlet => {
      retailOutlet.selected = false;
      retailOutlet.icon = 'accept';
    });
    this.getTypeFilter();
  }

  getTypeFilter(): void {
    this.navigateUrl();
  }

  sortTable(item: { sort?: SortType; field: 'id' | 'number' | 'date' }): void {
    this.orderBy = item.field;
    if (item.sort) {
      this.sort = item.sort === 'desc' ? SortType.asc : SortType.desc;
    } else {
      this.sort = SortType.desc;
      this.menu.forEach(el => {
        el.sort = el.field !== item.field ? undefined : el.sort;
      });
    }
    this.list = [];
    item.sort = this.sort;
    this.navigateUrl();
  }

  // private methods
  private listUpdater() {
    this.intervalUpdater = setInterval(() => {
      this.loadList();
    }, 30000);
  }

  private navigateUrl(seek: {seek?: string, seekDir?: SeekDirection} = {}) {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({
        seek: seek.seek,
        seekDir: seek.seekDir,
        actions: this.getSelectedActions(),
        deliveryType: this.deliveryType,
        searchText: this.query,
        date: this.date,
        decor: this.decor,
        sort: this.sort,
        orderBy: this.orderBy,
        decored: this.decored,
        storeIds: this.getSelectedStoreIds(),
      }),
      relativeTo: this.aRoute
    });
  }

  private getSelectedActions(): OrderAction[] {
    return this.types.filter(type => type.selected).map(type => type.id);
  }

  private async getRetailOutlets() {
    const result = await this.storeService.getRetailOutlets(this.auth.user.store.id);
    if (result.length > 0) {
      this.retailOutlets.push(this.auth.user.store);
      this.retailOutlets.push.apply(this.retailOutlets, result);
      this.retailOutlets.forEach(r => {
        r.selected = true;
        this.storeIds.push(r.id);
      });
      this.getTypeFilter();
    }
  }

  private getSelectedStoreIds(): number[] {
    return this.retailOutlets.filter(ro => ro.selected).map(ro => ro.id);
  }
}
