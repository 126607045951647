<div class="m-widget3" *ngIf="events">
  <div class="m-widget3__item" *ngFor="let event of events">
    <div class="m-widget3__header">
      <div class="m-widget3__info">
        <span class="m-widget3__username"><strong>{{ event.date | date: 'dd.MM HH:mm' }}</strong> - <span [title]="event.actorFullName + ' - ' + event.actor">{{ event.actorFullName | fio }}</span></span>
      </div>
      <span class="m-widget3__status m--font-info">
        <!--<span class="m-badge m-badge&#45;&#45;{{eventList[event.type]?.badge }} m-badge&#45;&#45;wide m-badge&#45;&#45;rounded">-->
          <!--{{ 'event.' + event.type | transloco }}-->
        <!--</span>-->
        <hc-tag [action]="event.type"></hc-tag>
      </span>
    </div>
    <div class="m-widget3__body" *ngIf="event.info">
      <p class="m-widget3__text">{{ event.info }}</p>
    </div>
  </div>
</div>
