import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { Process } from '@confectionary-app/process/process';
import { ProcessService } from '@confectionary-app/process/process.service';
import {
  DetailedRecipeModel,
  extractNumber,
  HxAuthService,
  HxCategoryService,
  RecipeType,
  StoreBasicModel,
  toRouterQueryParams,
  uiLabel,
  UserBasicModel,
  WorkflowModel,
  WorkflowStepModel
} from 'hx-services';

import { HxToastrService } from 'hx-component';
import { differenceInDays } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';

interface DetailedRecipe {
  recipes: DetailedRecipeModel[];
  isModified?: boolean;
}

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessComponent implements OnInit, OnDestroy {
  process: {
    user?: UserBasicModel;
    workflow?: WorkflowModel;
    step?: WorkflowStepModel;
  } = {};
  processObj: Partial<Process> = {
    totalAmount: 1,
  };
  groupedRecipes: DetailedRecipe[] = [];
  outputRecipes: DetailedRecipeModel[] = [];
  isLoading = {
    recipes: false,
    outputRecipes: false,
    submit: false
  };
  params: {
    userId?: number; workflowId?: number; stepId?: number;
  } = {};
  showAlert = false;
  timer: any;
  isCfOutput = false;
  store!: StoreBasicModel;
  private sub: Subscription;
  showModal: boolean = false;

  constructor(
    private processService: ProcessService,
    private toastr: HxToastrService,
    private titleService: Title,
    private categoryService: HxCategoryService,
    private auth: HxAuthService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.store = this.auth.user.store;
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.params = {
        userId: extractNumber('userId', paramMap),
        workflowId: extractNumber('workflowId', paramMap),
        stepId: extractNumber('stepId', paramMap),
      };
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.titleService.setTitle(`Confectionery`);
    this.destroyTimer();
  }

  private destroyTimer() {
    if (!this.timer) {
      return;
    }

    this.showAlert = false;
    clearTimeout(this.timer);
  }

  loadRecipes() {
    if (this.process.workflow && this.process.step) {
      this.isLoading.recipes = true;
      this.isLoading.outputRecipes = true;
      this.groupedRecipes = [];
      this.isCfOutput = false;
      this.processService.getRecipe(this.process.step.id).subscribe(result => {
        this.outputRecipes = result.filter(r => r.type === RecipeType.OUTPUT_PRODUCT);
        const arr: DetailedRecipeModel[] = [...result.filter(r => r.type === RecipeType.INPUT_PRODUCT)];
        const groupIdArr = arr.map(item => item.group).filter((v, i, item) => item.indexOf(v) === i);

        this.isCfOutput = this.outputRecipes.filter(t => t.invoiceAmount).length > 0;

        groupIdArr.forEach(numb => {
          const groupArr: DetailedRecipeModel[] = arr.filter(item => item.group === numb);
          groupArr.sort((first, second) => {
            return first.priority - second.priority;
          });
          const isModified = groupArr.some(recipe => differenceInDays(Date.now(), recipe.modifyDate) <= 14);
          this.groupedRecipes.push({recipes: groupArr, isModified: isModified});
        });

        this.isLoading.recipes = false;
        this.isLoading.outputRecipes = false;
      });
    }
  }

  changeModal() {
    this.showModal = !this.showModal;
  }

  changeUser() {
    this.params = {};
    this.process = {};
    this.updateRouter();
  }

  changeData() {
    this.params = {};
    this.process = {};
    this.processObj = {totalAmount: 1};
    this.updateRouter();
  }

  changeWorkflow() {
    this.params.workflowId = undefined;
    this.process.workflow = undefined;
    this.changeStep();
  }

  changeStep() {
    this.processObj = {totalAmount: 1};
    this.process.step = undefined;
    this.params.stepId = undefined;
    this.updateRouter();
  }

  updateRouter() {
    this.destroyTimer();

    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  addItem() {
    this.processObj.totalAmount += 1;
    if (this.processObj.totalAmount === 0) {
      this.processObj.totalAmount = 1;
    }
  }

  removeItem() {
    this.processObj.totalAmount -= 1;
    if (this.processObj.totalAmount === 0) {
      this.processObj.totalAmount = -1;
    }
  }

  async submitData() {
    if ((this.processObj.totalAmount ?? 0) === 0) {
      return this.toastr.warning(this.tr.translate('process.ts.addProduct'));
    }
    this.isLoading.submit = true;
    this.processObj.userId = this.process.user.id;
    this.processObj.workflowId = this.process.workflow.id;
    this.processObj.workflowStepId = this.process.step.id;
    try {
      await this.processService.sendProcess(this.processObj);
      this.showAlert = true;
      this.resetProcess();
    } finally {
      this.isLoading.submit = false;
    }
  }

  onUserChanged(user?: UserBasicModel) {
    this.params.userId = user?.id;
    this.process.user = user;
  }

  onWorkflowChanged(wf?: WorkflowModel) {
    this.params.workflowId = wf?.id;
    this.process.workflow = wf;
  }

  onStepChanged(step?: WorkflowStepModel) {
    this.process.step = step;
    this.params.stepId = step?.id;
    this.updateRouter();
    this.loadRecipes();

    // if (this.process.step) {
    //   const id = this.process.step.outputProductInfo.unitOfMeasure || null;
    //   if (id) {
    //     this.processService.getUnitOfMeasure(id).subscribe(result => {
    //       this.uom = result.data.unitofmeasure || null;
    //     });
    //   }
    // }
    this.titleService.setTitle(`${ uiLabel(this.tr.getActiveLang(), this.process.step?.title) } | Confectionery`);
  }

  back() {
    if (this.params.userId && !this.params.workflowId) {
      this.changeUser();
    } else if (this.params.userId && this.params.workflowId && !this.params.stepId) {
      this.changeWorkflow();
    } else if (this.params.stepId) {
      this.changeStep();
    }
  }

  private resetProcess() {
    this.timer = setTimeout(() => {
      this.showAlert = false;
      this.changeData();
    }, 1000);

    this.processObj = {totalAmount: 1};
    this.loadRecipes();
  }
}
