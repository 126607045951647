import { Component, Input } from '@angular/core';
import { HxErrorHandlerService } from 'hx-component';
import { AppEventModel, HxEventService } from 'hx-services';

/**
 * Timeline of order events
 * @example `<app-event-list [id]="id"></app-event-list>`
 */
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
})
export class EventListComponent {
  @Input()
  set id(val: number) {
    if (val) {
      this.getEvents(val);
    }
  }
  events: AppEventModel[] = [];

  constructor(
    private eventService: HxEventService,
    private errorService: HxErrorHandlerService,
  ) {
  }

  private getEvents(id: number) {
    this.eventService.getOrderEventList(id).subscribe(result => {
      this.events = result;
    }, err => this.errorService.check(err.error));
  }
}
