import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OhcService } from '@confectionary-app/service/ohc.service';
import { HxToastrService } from 'hx-component';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class StorageHttpInterceptor implements HttpInterceptor {

  constructor(
    private toastr: HxToastrService,
    private ohcService: OhcService,
    private tr: TranslocoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = req.clone({ headers: req.headers.set('Accept-Language', this.tr.getActiveLang()) });
    return next.handle(clonedReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.ohcService.logout();
        }

        if (error.status === 502) {
          this.toastr.error(this.tr.translate('http-interceptor.error',{error: error.status}));
          this.toastr.info(this.tr.translate('http-interceptor.reload'));
        }
      }

      return observableThrowError(error);
    }));
  }

}
