import { Component, Input } from '@angular/core';
import { AppEventModel, HxEventService } from 'hx-services';

/**
 * Component of invoice event list
 */
@Component({
  selector: 'hc-invoice-events',
  templateUrl: './invoice-events.component.html',
  styleUrls: ['./invoice-events.component.css']
})
export class InvoiceEventsComponent {
  events: AppEventModel[] = [];
  invoiceEventList = {
    'INVOICE_NEW': {
      badge: 'info'
    },
    'INVOICE_SENT': {
      badge: 'brand'
    },
    'INVOICE_SHIPMENT': {
      badge: 'warning'
    },
    'INVOICE_ACCEPTED': {
      badge: 'success'
    },
    'INVOICE_CANCELLED': {
      badge: 'danger'
    },
    'INVOICE_REJECTED': {
      badge: 'danger'
    },
    'INVOICE_DISCARDED': {
      badge: 'danger'
    }
  };

  @Input()
  set id(val: number) {
    if (val) {
      this.loadEvents(val);
    }
  }

  isLoading = {
    list: false,
  };

  constructor(
    private eventService: HxEventService,
  ) {
  }

  private loadEvents(id: number) {
    this.isLoading.list = true;
    this.eventService.getInvoiceEventList(id).subscribe(result => {
      this.events = result;
      this.isLoading.list = false;
    }, err => {
      this.isLoading.list = false;
    });
  }

}
