import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converter'
})
export class ConverterPipe implements PipeTransform {
  scheme = {
    'грамм': 'гр',
    'килограмм': 'кг',
    'литр': 'л',
    'мешок': 'меш',
    'миллиграмм': 'мг',
    'миллилитр': 'мл',
    'упаковка': 'упак',
    'штука': 'шт',
  };

  transform(value: string, args?: any): any {

    if (this.scheme[value.toLowerCase()]) {
      return this.scheme[value.toLowerCase()];
    }

    return value;
  }

}
