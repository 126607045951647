import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OhcService } from '@confectionary-app/service/ohc.service';
import { HxAuthService, HxStoreService, HxUserService, StoreFullModel } from 'hx-services';
import { environment } from '@confectionary-env/environment';

/**
 * Component of sidebar with mainmenu
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuList = [
    'order-stats',
    'order-list',
    'process',
    'history',
    'invoices',
    'storage',
  ];
  appVersion = environment.version;
  showSidebar = false;
  store: StoreFullModel;
  authUserFullname!: string;

  constructor(
    private router: Router,
    private ohcService: OhcService,
    private storeService: HxStoreService,
    private userService: HxUserService,
    private auth: HxAuthService,
  ) { }

  ngOnInit() {
    this.authUserFullname = this.auth.user.fullname;

    const storeId = this.ohcService.getStoreId();
    if (storeId) {
      this.getStore(storeId);

      if (storeId !== this.auth.user.store.id) {
        this.updateDefaultStore(storeId);
      }
    }
  }

  private getStore(storeId: number) {
    this.storeService.getFullStore(storeId).then(result => {
      this.store = result;
    }, err => {
      console.error(err);
    });
  }

  private updateDefaultStore(storeId: number) {
    this.userService.setDefaultStore(storeId).subscribe(() => {
      document.location.reload();
    }, err => {
      console.error(err);
    });
  }

  goPage(item) {
    this.closeSidebar();
    this.router.navigateByUrl(`/${item}`);
  }

  logout() {
    this.closeSidebar();
    this.ohcService.logout();
  }

  private closeSidebar() {
    this.showSidebar = false;
  }
}
