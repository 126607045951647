import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { extractNumber, extractNumberArr, HxOrderService, isoDate, OrderAction, OrderRowModel, SeekDirection, SortType, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';


interface MenuItem {
  title: string;
  field?: 'id' | 'number' | 'date';
  sort?: SortType;
}

/**
 * Component list of orders by productinfo
 */
@Component({
  selector: 'app-order-stat-detail',
  templateUrl: './order-stat-detail.component.html',
  styleUrls: ['./order-stat-detail.component.css']
})
export class OrderStatDetailComponent implements OnInit, OnDestroy {
  list: OrderRowModel[] = [];
  isLoading = {
    list: true
  };
  date: string;
  sort: SortType = SortType.desc;
  menu: MenuItem[] = [
    {
      title: 'номер',
      sort: SortType.desc,
      field: 'number'
    },
    {
      title: 'дата создания',
    },
    {
      title: 'дата доставки',
      field: 'date'
    }
  ];
  statusList = {
    'CREATED': {
      badge: 'info'
    },
    'PACKED': {
      badge: 'warning'
    },
    'DECORED': {
      badge: 'focus'
    },
    'SHIPPED': {
      badge: 'warning'
    },
    'RECEIVED': {
      badge: 'success'
    },
    'CANCELLED': {
      badge: 'danger'
    },
    'NEW': {
      badge: 'info'
    },
    'REJECTED': {
      badge: 'danger'
    },
    'SHIPMENT': {
      badge: 'warning'
    },
    'DISCARDED': {
      badge: 'focus'
    },
    'DELIVERED': {
      badge: 'focus'
    },
    'PAID': {
      badge: 'success'
    },
    'COMPLETED': {
      badge: 'success'
    },
    'TAKEN': {
      badge: 'warning'
    },
    'RETURNED': {
      badge: 'metal'
    },
  };
  productActions: OrderAction[] = [OrderAction.CREATED, OrderAction.DECORED, OrderAction.PACKED, OrderAction.TAKEN, OrderAction.RECEIVED, OrderAction.SHIPPED];
  seek: {
    last?: string;
    first?: string;
    val?: string;
    hasNext?: boolean;
    dir?: SeekDirection;
  } = {};
  private storeIds: number[] = [];
  private id?: number;
  private orderBy: 'id' | 'number' | 'date' = 'id';
  private sub: Subscription;

  constructor(
    private orderService: HxOrderService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.id = extractNumber('id', paramMap);
      this.date = paramMap.has('date') ? paramMap.get('date') : isoDate(Date.now());
      this.storeIds = extractNumberArr('storeIds', paramMap);
      this.seek = {
        dir: paramMap.get('seekDir') as SeekDirection ?? SeekDirection.next,
        val: paramMap.get('seek') ?? undefined,
      };
      this.loadList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sortTable(item: MenuItem): void {
    if (!item.field) {
      return;
    }
    this.orderBy = item.field;
    if (item.sort) {
      this.sort = item.sort === SortType.desc ? SortType.asc : SortType.desc;
    } else {
      this.sort = SortType.desc;
      this.menu.forEach(el => {
        if (el.field !== item.field) {
          el.sort = undefined;
        }
      });
    }
    this.list = [];
    item.sort = this.sort;
    this.navigateUrl();
  }

  seekPrev() {
    this.navigateUrl({seek: this.seek.first, seekDir: SeekDirection.prev});
  }

  seekNext() {
    this.navigateUrl({seek: this.seek.last, seekDir: SeekDirection.next});
  }

  private async loadList() {
    this.isLoading.list = true;
    this.list = [];
    try {
      const {list, first, last, hasNext} = await this.orderService.getOrderList({
        seek: this.seek.val,
        seekDir: this.seek.dir,
        date: this.date,
        sort: this.sort,
        orderBy: this.orderBy,
        storeIds: this.storeIds,
        actions: this.productActions,
        productInfoIds: [this.id],
      });
      this.seek.first = first;
      this.seek.last = last;
      this.seek.hasNext = hasNext;
      this.list = list;
    } finally {
      this.isLoading.list = false;
    }
  }

  private navigateUrl(seek: { seek?: string, seekDir?: SeekDirection } = {}) {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({
        seek: seek.seek,
        seekDir: seek.seekDir,
        id: this.id,
        date: this.date,
        sort: this.sort,
        orderBy: this.orderBy,
        storeIds: this.storeIds
      }),
      relativeTo: this.aRoute
    });
  }
}

