import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OhcService {
  static storeId = 'cf-store-id';

  constructor(
    private keycloakService: KeycloakService,
    private httpClient: HttpClient,
  ) { }

  logout() {
    // localStorage.removeItem(OhcService.storeId);
    this.keycloakService.logout();
  }

  setStoreId(id: number) {
    localStorage.setItem(OhcService.storeId, JSON.stringify(id));
  }

  getStoreId(): number {
    return JSON.parse(localStorage.getItem(OhcService.storeId));
  }

  sendCode(code: string): Observable<any> {
    return this.httpClient.get(`/api/vanilla/cf/ohc`, {params: new HttpParams().set('code', code)});
  }
}
