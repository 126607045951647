import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Компонент иконки можно использовать как директиву
 * спрайт свг иконок генерируется через https://icomoon.io/app (проект для импорта иконок в happycake-svg-sprite.json)
 * дефолтные размер иконок 16px
 *
 *  example:
 *  `<hc-icon icon="nameOfIcon"></hc-icon>`
 *  `<hc-icon [icon]="nameOfIcon"></hc-icon>` динамическое название
 * nameOfIcon - название иконки (смотри hc-icon.component.css)
 */
@Component({
  selector: 'hc-icon',
  exportAs: 'hcIcon',
  template: `<i [class]="'hc-icon hc-icon--' + icon.toLowerCase()" [ngClass]="{'is--disable': !enable}"></i>`,
  styleUrls: ['./hc-icon.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HcIconComponent {
  @Input() enable = true;
  @Input() icon: string;
}
