<ul class="hc-proccess-select hc-proccess--step">
  <ng-container *ngIf="!isLoading.list && workflowSteps.length">
    <li class="hc-proccess-select__item hc-proccess-select__item--center" *ngFor="let item of workflowSteps"
        (click)="selectStep(item)">
      <div class="hc-proccess-select__name">
        <div>
          {{ item.title | uiLabel }}
        </div>
      </div>
    </li>
  </ng-container>

  <div class="hc-step-process__info">
    <h1 class="hc-step-process__line">
      {{ process.workflow?.title | uiLabel }}
    </h1>

    <div class="hc-step-process__adder">
      <div class="hc-step-process__line">
        <button type="button" class="hc-button hc-button--danger hc-process__toggler hc-disable-dbl-tap-zoom" (click)="removeItem()">
          <img src="/assets/images/minus.svg" alt="">
        </button>

        <input type="number" class="hc-input hc-process__number" placeholder="0" [(ngModel)]="processObj.totalAmount">

        <button type="button" class="hc-button hc-button--success hc-process__toggler hc-disable-dbl-tap-zoom" (click)="addItem()">
          <img src="/assets/images/plus.svg" alt=""></button>
      </div>
      <div class="hc-step-process__line">
        шт.
      </div>
    </div>

    <div class="hc-step-process__line_top">
      <button *ngIf="!showAlert" type="button" class="hc-button hc-button--success hc-process__btn hc-disable-dbl-tap-zoom"
              [disabled]="isLoading.submit" (click)="submitData()">
        {{ (isLoading.submit ? 'step-select.sending' : 'step-select.send') | transloco }}</button>
    </div>
  </div>
</ul>

<div *ngIf="!workflowSteps.length && !isLoading">
  {{ 'step-select.fatal' | transloco }}
</div>

<div *ngIf="!workflowSteps.length && isLoading">
  {{ 'step-select.loading' | transloco }}
</div>

<div class="hc-alert" *ngIf="showAlert">
  <h2 class="hc-alert__content">
    <div class="hc-alert__icon"></div>

    <div class="hc-alert__text">
      {{ 'step-select.success' | transloco }}
    </div>
  </h2>
</div>
