import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentType, HxUserService, UserBasicModel, UserProfileModel } from 'hx-services';
import { OhcService } from '@confectionary-app/service/ohc.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {
  @Input() userId?: number;
  @Output() userChange = new EventEmitter<UserBasicModel | undefined>();

  users: UserProfileModel[] = [];
  isLoading: boolean;

  constructor(
    private userService: HxUserService,
    private ohcService: OhcService,
  ) {
  }

  ngOnInit() {
    this.getUsers();
  }

  getImage(fileId): string {
    return fileId ? `${location.origin}/api/vanilla/files/download/${fileId}` : `/assets/images/no-user.svg`;
  }

  private getUsers() {
    this.isLoading = true;

    this.userService.getEmployees(this.ohcService.getStoreId(), ComponentType.cf).subscribe(result => {
      this.users = result;
      if (this.userId) {
        const item = this.users.find(el => el.id === this.userId);
        this.selectUser(item);
      }
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  selectUser(item: UserProfileModel) {
    this.userChange.emit(item);
  }
}
