<div class="hc-sidebar">
  <input id="burger-navigation" [(ngModel)]="showSidebar" class="burger-checkbox" type="checkbox">

  <label for="burger-navigation" class="burger-menu" tabindex="1">
    <div class="burger-menu__overlay"></div>

    <div class="burger-menu__trigger">
      <div class="burger-menu__line"></div>
      <div class="burger-menu__line"></div>
      <div class="burger-menu__line"></div>
    </div>
  </label>
  <!-- label#burger-navigation -->

  <nav class="page__nav hc-mainmenu" aria-label="Main navigation" role="menu">
      <!-- <img class="hc-logo" src="/assets/images/logotip.svg" (click)="goPage('')" alt="Happy Cake IS: Confectionery"> -->
    <ul class="hc-mainmenu__list">
      <li class="hc-mainmenu__item hc-mainmenu__item--profile">
        <span class="hc-mainmenu__link icon-profile">{{ authUserFullname }}</span>
      </li>
      <li class="hc-mainmenu__item hc-mainmenu__item--{{item}}" *ngFor="let item of menuList">
        <a (click)="goPage(item)" class="hc-mainmenu__link">{{ 'mainmenu.' + item | transloco }}</a>
      </li>
      <li class="hc-mainmenu__item hc-mainmenu__item--profile">
        <span class="hc-mainmenu__link">{{ appVersion }}</span>
      </li>
    </ul>

    <div class="hc-store" *ngIf="store">
      <div class="name-store">
        <span class="title-store">{{ 'sidebar.shop' | transloco }}</span>
        <span class="hc-store__title">{{ store.title | uiLabel }}</span>
        <span class="title-store">{{ store.address }}</span>
      </div>
    </div>

    <hx-language-select></hx-language-select>

    <ul class="hc-mainmenu__list hc-mainmenu__list--bottom">
      <li class="hc-mainmenu__item hc-mainmenu__item--exit">
        <a (click)="logout()" class="hc-mainmenu__link">
          {{ 'sidebar.logOff' | transloco }}
        </a>
      </li>
    </ul>
  </nav>
  <!-- .hc-mainmenu -->
</div>
