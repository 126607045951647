import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { DetailedRecipeModel } from 'hx-services';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  limit = 100;

  constructor(
    private _http: HttpClient,
  ) { }

  getRecipe(id: number): Observable<DetailedRecipeModel[]> {
    return this._http.get<DetailedRecipeModel[]>(`/api/vanilla/recipes/detailed?stepId=${id}`);
  }

  sendProcess(body?: {userId?: number, workflowId?: number, workflowStepId?: number, totalAmount?: number}): Promise<void> {
    return firstValueFrom(this._http.post<void>(`/api/vanilla/cf/process`, body));
  }

  sendProcessWorkflow(body?: {userId?: number, workflowId?: number, totalAmount?: number}): Promise<void> {
    return firstValueFrom(this._http.post<void>(`/api/vanilla/cf/process/by-workflow`, body));
  }

  getWorkflowStep(workflowId: number): Observable<any> {
    return this._http.get(`/api/vanilla/workflows/${workflowId}/steps?limit=${this.limit}`);
  }
}
